<template>
  <div>
    <div
      v-for="item in businessModel"
      :key="item.id"
    >
      <b-card :title="item.title">
        <b-table-simple
          bordered
          hover
        >
          <b-thead>
            <b-tr>
              <b-th>Soru</b-th>
              <b-th
                class="text-center"
                width="200"
              >
                Notlandırma
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="question in item.questions"
              :key="question.id"
            >
              <b-td :colspan="question.score === null? '2' : ''">
                <div v-if="question.text">
                  <div class="font-weight-bolder">
                    {{ question.question }}
                  </div>
                  <small class="text-primary">{{ question.text }}</small>
                </div>
                <div v-else>
                  <div>{{ question.question }}</div>
                </div>
              </b-td>
              <b-td
                v-if="question.score !== null"
                class="text-center font-weight-bolder"
              >
                {{ question.score }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-th
                class="align-middle text-primary"
              >
                Ortalama
              </b-th>
              <b-th class="text-center text-primary font-medium-4">
                {{ item.score_avg }}
              </b-th>
            </b-tr>
            <b-tr>
              <b-th
                class="align-middle text-primary"
              >
                Performans
              </b-th>
              <b-th class="text-center text-primary font-medium-4">
                %{{ item.performance }}
              </b-th>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BTableSimple, BTd, BTr, BTbody, BThead, BTh, BBadge, BTfoot,
} from 'bootstrap-vue'

export default {
  name: 'BusinessModelView',
  components: {
    BCard,
    BBadge,
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    businessModel() {
      return this.$store.getters['businessModel/getBusinessModel']
    },
  },
  created() {
    this.getBusinessModel()
  },
  methods: {
    getBusinessModel() {
      this.$store.dispatch('businessModel/businessModel', this.$route.params.id)
    },
  },
}
</script>

<style scoped>

</style>
